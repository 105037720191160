import React from "react";
import { css } from "goober";
import { t } from "i18next";

import { appURLs } from "@/app/constants.mjs";
import GameGuideTable from "@/marketing/GameGuideTable.jsx";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import { SquareAvatar } from "@/shared/Avatar.jsx";

const PALS = {
  sparkit: {
    id: "sparkit",
    label: ["gen-palworld:sparkit.name", "Sparkit"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/ElecCat.webp`,
  },
  relaxaurus: {
    id: "relaxaurus",
    label: ["gen-palworld:relaxaurus.name", "Relaxaurus"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/LazyDragon.webp`,
  },
  relaxaurusLux: {
    id: "relaxaurus-lux",
    label: ["gen-palworld:relaxaurus-lux.name", "Relaxaurus Lux"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/LazyDragon_Electric.webp`,
  },
  lamball: {
    id: "lamball",
    label: ["gen-palworld:lamball.name", "Lamball"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/SheepBall.webp`,
  },
  penking: {
    id: "penking",
    label: ["gen-palworld:penking.name", "Penking"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/CaptainPenguin.webp`,
  },
  verdash: {
    id: "verdash",
    label: ["gen-palworld:verdash.name", "Verdash"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/GrassRabbitMan.webp`,
  },
  grintale: {
    id: "grintale",
    label: ["gen-palworld:grintale.name", "Grintale"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/NaughtyCat.webp`,
  },
  paladius: {
    id: "paladius",
    label: ["gen-palworld:paladius.name", "Paladius"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/SaintCentaur.webp`,
  },
  petallia: {
    id: "petallia",
    label: ["gen-palworld:petallia.name", "Petallia"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/FlowerDoll.webp`,
  },
} as const;

const rows = [
  {
    parent1: PALS.sparkit,
    parent2: PALS.relaxaurus,
    child: PALS.relaxaurusLux,
  },
  {
    parent1: PALS.lamball,
    parent2: PALS.penking,
    child: PALS.verdash,
  },
  {
    parent1: PALS.lamball,
    parent2: PALS.grintale,
    child: PALS.verdash,
  },
  {
    parent1: PALS.lamball,
    parent2: PALS.paladius,
    child: PALS.petallia,
  },
];

export default function HomeBreedingCalculatorTile() {
  return (
    <div className={TileContainer()}>
      <GameGuideTileLg
        title={["palworld:pals.breedingCalculator", "Breeding Calculator"]}
        description={[
          "home:guides.palworld.breedingCalculator.description",
          "Palworld Breeding Combos and Calculator with Passives & Chances",
        ]}
        buttonText={[
          "common:navigation.viewBreedingCalculator",
          "View Breeding Calculator",
        ]}
        href={"/palworld/breeding-calculator"}
      >
        <GameGuideTable className={Table()}>
          <GameGuideTable.Header>
            <div>{t("palworld:breeding.parents", "Parents")}</div>
            <div className="child-col">
              {t("palworld:breeding.child", "Child")}
            </div>
          </GameGuideTable.Header>
          <GameGuideTable.Body numRows={4}>
            {rows.map((row, i) => (
              <GameGuideTable.Row
                key={i}
                link={`/palworld/database/pals/${row.child.id}`}
              >
                <div className="flex gap-3 align-center">
                  <SquareAvatar avatar={row.parent1.imageSrc} title="" />
                  <SquareAvatar avatar={row.parent2.imageSrc} title="" />
                  <div className="flex gap-2 align-center">
                    <span className="pal-name">{t(...row.parent1.label)}</span>
                    <span>+</span>
                    <span className="pal-name">{t(...row.parent2.label)}</span>
                  </div>
                </div>
                <div className="flex gap-3 align-center child-col">
                  <SquareAvatar avatar={row.child.imageSrc} title="" />
                  <span className="pal-name">{t(...row.child.label)}</span>
                </div>
              </GameGuideTable.Row>
            ))}
          </GameGuideTable.Body>
        </GameGuideTable>
      </GameGuideTileLg>
    </div>
  );
}

const TileContainer = () => css`
  .card-contents {
    min-height: auto;
  }
`;

const Table = () => css`
  img {
    border: 1px solid var(--shade3-15);
    background: var(--shade3-15);
  }

  .pal-name {
    color: #e3e5ea;
  }

  .child-col {
    min-width: 160px;
  }
`;
